import type { SVGProps } from 'react';
import tailwindConfig from 'tailwind.config';

import SvgIcon from '@/app/components/VectorImage/svgs/SvgIcon';
import { ICONS } from '@/app/consts/icons';
import type { TailwindThemeColor } from '@/app/types/tailwind';
import { cn } from '@/utils/ui';

export type IIconProps = SVGProps<SVGSVGElement> & {
  readonly className?: string;
  readonly color?: TailwindThemeColor;
  readonly height?: number;
  readonly icon: keyof typeof ICONS;
  readonly strokeWidth?: string;
  readonly variant?: 'line' | 'glyph';
  readonly width?: number;
};

const Icon = ({
  className,
  color = 'gray-80',
  height = 24,
  icon,
  variant = 'line',
  width = 24,
  strokeWidth = 'null',
  ...props
}: IIconProps) => {
  const { title, paths, viewbox } = ICONS[icon];

  if (!paths[variant]) {
    // eslint-disable-next-line no-console
    console.warn(`Icon ${title} does not support the ${variant} variant`);
    return null;
  }

  return (
    <SvgIcon title={title}>
      <svg
        fill="none"
        height={`${height}px`}
        viewBox={viewbox ?? '0 0 24 24'}
        width={`${width}px`}
        xmlns="http://www.w3.org/2000/svg"
        className={cn(
          'inline-block align-middle',
          variant === 'line' && 'stroke-2',
          className,
        )}
        {...props}
      >
        {paths[variant]?.map((path) => (
          <path
            d={path}
            fill={
              variant === 'glyph'
                ? tailwindConfig.theme.extend.colors[color]
                : undefined
            }
            key={path}
            stroke={
              variant === 'line'
                ? tailwindConfig.theme.extend.colors[color]
                : undefined
            }
            strokeLinecap={'round'}
            strokeWidth={strokeWidth}
          />
        ))}
      </svg>
    </SvgIcon>
  );
};

export default Icon;
