import plugin from 'tailwindcss/plugin';

const noScrollbarPlugin = plugin(({ addComponents }) => {
  addComponents({
    '.no-scrollbar': {
      // IE and Edge
      '-ms-overflow-style': 'none',
      // Firefox
      'scrollbar-width': 'none',
      // Chrome,  Safari, and Opera
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  });
});

export default noScrollbarPlugin;
