import tailwindcssAnimatePlugin from 'tailwindcss-animate';

import clipPlugin from './tailwind-plugins/clip';
import noScrollbarPlugin from './tailwind-plugins/no-scrollbar';
import noJsPlugin from './tailwind-plugins/nojs';

/** @type {import('tailwindcss').Config} */
const config = {
  content: ['./src/**/*.{ts,jsx,tsx,mdx}', './src/**/*.module.scss'],
  important: '.tailwind',
  plugins: [
    clipPlugin,
    noJsPlugin,
    noScrollbarPlugin,
    tailwindcssAnimatePlugin,
  ],
  prefix: '',
  theme: {
    container: {
      center: true,
      padding: '1rem',
      screens: {
        '2xl': '1400px',
      },
    },
    extend: {
      animation: {
        scrollTeaserPillSlide: 'scrollTeaserPillSlide 3s ease infinite',
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
      },
      aspectRatio: {
        '4/3': '4 / 3',
        '5/4': '5 / 4',
        '6/4': '6 / 4',
      },
      backdropBlur: { 'backdrop-blur-20': '20px' },
      boxShadow: { '1xl': '0px 15px 50px -4px rgba(16, 24, 40, 0.06)' },
      colors: {
        'burnt-orange-25': '#994921',
        'burnt-orange-5': '#fcf7f4',
        'burnt-orange-50': '#e6b096',
        'burnt-orange-700': '#8f441f',
        'burnt-orange-80': '#d68156',
        'burnt-orange': '#CC612C',
        'enzo-blue-25': '#7a9eae',
        'enzo-blue-5': '#fafdfe',
        'enzo-blue-50': '#d1e9f4',
        'enzo-blue-80': '#b5dced',
        'enzo-blue': '#A2D3E8',
        'enzo-bright-25': '#64a4b6',
        'enzo-bright-5': '#f9fdfe',
        'enzo-bright-50': '#c2edf9',
        'enzo-bright-80': '#9de2f5',
        'enzo-bright': '#85DBF2',
        'gray-15': '#E3E3E3',
        'gray-25': '#313531',
        'gray-5': '#f6f6f6',
        'gray-50': '#a0a3a0',
        'gray-60': '#70757D',
        'gray-80': '#676c67',
        'gray-400': '#9fa3ab',
        'green-success-25': '#40a562',
        'green-success-5': '#f7fdf9',
        'green-50': '#eefbf3',
        'green-success-50': '#aaeec1',
        'green-success-50-shade': '#2B6E42',
        'green-success-80': '#55DC83',
        'green-success': '#55DC83',
        'helsinki-blue-25': '#233856',
        'helsinki-blue-5': '#f5f6f8',
        'helsinki-blue-50': '#97a5b9',
        'helsinki-blue-80': '#596e8f',
        'helsinki-blue-dark': '#101926',
        'helsinki-blue-light': '#526075',
        'helsinki-blue': '#2F4A73',
        'light-gray-50': '#f6f7f8',
        'living-coral-25': '#bd4c42',
        'living-coral-5': '#fff7f7',
        'living-coral-50': '#feb2ac',
        'living-coral-80': '#fd8479',
        'living-coral-700': '#B0473E',
        'living-coral': '#FC6558',
        'sandstone-25': '#ada79e',
        'sandstone-5': '#fefdfd',
        'sandstone-50': '#f3efe9',
        'sandstone-80': '#ebe5dc',
        'sandstone-light': '#F6F3EF',
        gray: '#414741',
        sandstone: '#E6DED3',
        white: '#ffffff',
        'rings-overlay': '#10192666',
      },
      fontFamily: {
        sans: ['var(--font-sans)'],
        serif: ['var(--font-serif)'],
      },
      fontSize: {
        '3.5xl': '2rem',
        '4.5xl': '2.5rem',
        '7.5xl': '5.25rem',
      },
      keyframes: {
        scrollTeaserPillSlide: {
          '0%': {
            opacity: '0',
            transform: 'translate3d(-50%, 0, 0)',
          },
          '50%': {
            opacity: '1',
          },
          '100%': {
            opacity: '0',
            transform: 'translate3d(-50%, 32px, 0)',
          },
        },
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
      },
      listStyleType: {
        none: 'none',
        disc: 'disc',
        decimal: 'decimal',
        circle: 'circle',
        'upper-roman': 'upper-roman',
        'lower-roman': 'lower-roman',
        'upper-alpha': 'upper-alpha',
        'lower-alpha': 'lower-alpha',
      },
      screens: {
        tablet: '768px',
        desktop: '1204px',
      },
      zIndex: {
        100: '100',
        150: '150',
        200: '200',
      },
    },
  },
};

export default config;
