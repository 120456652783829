import type { ReactElement } from 'react';
import { cloneElement } from 'react';

interface SvgIconProps {
  readonly title?: string;
  readonly children: ReactElement;
}

/**
 * SvgIcon component for rendering accessible SVG icons
 *
 * IMPORTANT - Define your raw svg as children of this component
 *
 * @param svgIconProps.title The accesible title for the svg image
 * @param svgIconProps.children The raw svg markup
 */

const SvgIcon = ({ title, children }: SvgIconProps) => {
  const svgChildren = children.props.children || [];
  return cloneElement(
    children,
    { role: 'img' },
    <title>{title}</title>,
    ...[svgChildren].flat(),
  );
};

export default SvgIcon;
