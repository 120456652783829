import plugin from 'tailwindcss/plugin';

const clipPlugin = plugin(({ addUtilities }) => {
  addUtilities({
    '.clip-polygon': {
      clip: 'rect(0, auto, auto, 0)',
      'clip-path': 'polygon(0 0, 0 100%, 100% 100%, 100% 0)',
    },
  });
});

export default clipPlugin;
